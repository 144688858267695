.menu-card {
  margin: 5px !important;
  min-height: 100px;
  width: 100%;
  border-radius: 20px !important;
}
.openhour-card {
  margin: 5px !important;
  height: 160px;
  width: 100%;
  border-radius: 20px !important;
}
.history-card {
  margin: 5px !important;
  height: 230px;
  width: 100%;
  border-radius: 20px !important;
}
.loading-spinner {
  color: #5AA7A7 !important;
}
.measurement-head {
  color: #5AA7A7 !important;
  font-size: 12px !important;
  font-weight: bold !important;
  white-space: nowrap;
}
.reserve-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 100px;
  height: 35px;
}
.reserve-disabled-button {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  border-radius: 10rem !important;
  width: 100px;
  height: 35px;
}
.history-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  margin: 5px !important;
  border-radius: 1rem !important;
  width: 30%;
  height: 60px;
  flex-wrap: wrap;
}
.history-disabled-button {
  background-color: #D3D3D3 !important; 
  border-color: #D3D3D3 !important; 
  margin: 5px !important;
  border-radius: 1rem !important;
  width: 30%;
  height: 60px;
  flex-wrap: wrap;
}
.history-reserve-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 110px;
  height: 40px;
}
.upload-button {
  background-color: #5AA7A7 !important; 
  border-color: #5AA7A7 !important;
  border-radius: 0rem !important;
  margin-left: 212px !important;
}
.remove-button {
  color: #474747 !important;
  font-weight: bold !important;
  align-self: end !important;
}
.submit-button {
  background-color: #5AA7A7 !important;
  border-color: #5AA7A7 !important; 
  color: #FFFFFF !important;
  border-radius: 10rem !important;
  width: 310px;
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}